import React from "react"

import CiMethodoStyle from "./ci-methodo.module.scss";

const CiGDPRFr = (props) => {

        return <section className={ CiMethodoStyle.methodo }>
            <div className={ CiMethodoStyle.inner }>
            <h3>RGPD</h3>
            <div className={ CiMethodoStyle.content }>
                <span>Vie privée</span>
                <br/><br/>
                Dernière modification : 8 mars 2021
                <br/><br/>
                <strong>Politique de protection des données </strong><br/><br/>
                <span>1. Responsable du traitement et destinataires des données à caractère personnel</span><br/><br/>
                    Nous vous informons que vos données à caractère personnel collectées plus généralement, dans le cadre des relations que vous pourriez être amené à avoir avec notre société, font l’objet d’un traitement parla société iAdvize SAS (“iAdvize”) basée au 9 Rue Nina Simone, Euronantes Gare, Le Berlingot bât. B – 44000 Nantes, France.
                    Les données collectées sont destinées à être utilisées par iAdvize. Elles seront également rendues accessibles à nos prestataires techniques (« sous-traitants » au sens de la règlementation), pour les stricts besoins de leur mission.
                    Les accès sont strictement encadrés par les politiques de sécurité en place de sorte qu’une intervention humaine sur des données reste très exceptionnelle. En outre, de tels accès sont régis par les clauses contractuelles types conformément aux exigences de la réglementation pour les transferts de données hors UE.
                <br/><br/>
                <span>2. Bases juridiques et finalités de la collecte des données à caractère personnel</span><br/><br/>
                    Lorsque certaines de vos données nous sont indispensables pour exécuter un contrat auquel vous ou votre société êtes parties, ou pour répondre à une obligation règlementaire, nous vous le signalons lors de la collecte.
                    Vos données seront utilisées de différentes manières, en fonction de la raison qui vous a conduit à entrer en relation avec notre société :<br/>

                    - Sur la base de votre consentement, que vous pouvez retirer à tout moment sans remettre en cause la licéité initiale de l'utilisation de vos données, pour l’organisation de toute opération promotionnelle ;<br/>
                    - Pour les besoins de l’exécution de votre contrat ou d’un contrat conclu avec la société que vous représentez (exécution des prestations, facturation, suivi de l’exécution du contrat, livraison, après-vente et réclamations, réalisation d’enquêtes de satisfaction, gestion du fichier client, gestion des impayés et des contentieux) ;<br/>
                    - Pour traiter et répondre à des demandes pouvant donner lieu à une contractualisation (demande d’informations sur nos offres) ;<br/>
                    - Pour répondre à nos obligations légales, comptables et fiscales (notamment de conservation de pièces comptables justificatives ; gestion des demandes de droits, de rectification et d’opposition ; tenue d’une liste d’opposition à la prospection) ;<br/>
                    - Sauf opposition de votre part et dans les limites de vos intérêts et droits, pour répondre à nos besoins légitimes, notamment de : Prospection, suivi de la relation client et de fidélisation client ; Gestion des avis des personnes sur nos solutions, services ou contenus dans l’intérêt d’améliorer les offres proposées par notre société ; Elaboration de statistiques commerciales.<br/>
                <br/><br/>
                <span>3. Droits des personnes</span><br/><br/>
                Vous disposez d’un droit d’accès, de rectification des données erronées vous concernant, et, dans les cas et selon les limites prévues par la règlementation, d’opposition, de suppression de certaines de vos données, d’en faire limiter l’usage ou de solliciter leur portabilité en vue de leur transmission à un tiers.
                Pour exercer vos droits, il vous suffit de nous écrire à l’adresse privacy@iadvize.com et d’y joindre, le cas échéant, toute pièce permettant de justifier votre identité et votre demande.<br/><br/>

                <span>4. Conservation des données à caractère personnel</span><br/><br/>
                Vos données seront conservées pendant la durée pendant laquelle elles sont nécessaires pour répondre aux finalités susvisées. Les données permettant de gérer la relation client seront conservées pendant 3 ans après la fin de la relation commerciale.  Les données collectées lors d’opération de prospection commerciale seront également conservées pendant 6 mois après la collecte.
                A l’issue de ces durées, les données seront supprimées des bases actives et, si besoin, archivées pendant une durée n’excédant pas les délais de prescription légale ou les obligations d’archivage applicables. Une fois ces délais expirés, les données seront détruites.<br/><br/> 
                <span>5. Caractère obligatoire ou facultatif de la fourniture des Données</span><br/><br/>
                Les Données indispensables à iAdvize pour atteindre les finalités décrites ci-dessus sont signalées par un astérisque sur le formulaire du contact. Si vous ne renseignez pas ces champs obligatoires, nous ne serons pas en mesure de donner suite à vos demandes et/ou de vous fournir les services souhaités.
                Les Données non signalées par un astérisque sont facultatives ; elles nous permettent de mieux vous connaître et d'améliorer nos communications et services à votre égard.<br/><br/>
                <span>6. Contacter le Délégué à la protection des données</span><br/><br/>
                Pour toute information complémentaire ou difficulté relative à la collecte ou au traitement de vos données, vous pouvez contacter notre délégué à la protection des données (DPO) à l’adresse suivante : privacy@iadvize.com.
                En cas de difficulté non résolue, vous pouvez saisir une autorité de contrôle.
                
                <br/><br/>
                <strong>DESTINATAIRES DES DONNÉES</strong>
                <br/><br/>

                Dans le cadre de la fourniture de nos services, nous pouvons transmettre ou donner accès à vos Données à nos services et sous-traitants techniques.
                iAdvize pourra être amenée à communiquer vos Données à des tiers dans les cas où une telle communication est autorisée et requise par la loi ou une décision de justice, ou si cette communication est nécessaire pour assurer la protection et la défense de ses droits.
                En dehors de ces cas, vos Données ne seront cédées ou rendues accessibles à aucun tiers sans votre accord préalable.
                iAdvize ne procède à aucun transfert de vos Données vers un pays tiers à l’EEE. En cas de transfert hors de l’EEE, de tels transferts seront encadrés par des garanties appropriées, telles les clauses contractuelles types.
                <br/><br/>
                <strong>VOS DROITS</strong>
                <br/><br/>
                Vous disposez d’un droit d’accès aux Données vous concernant, du droit d’en demander la rectification, l’effacement ou la portabilité, ainsi que du droit de demander la limitation du traitement de vos Données et de retirer votre consentement.
                Vous avez également la possibilité de vous opposer, à tout moment :
                Ces droits peuvent être exercés à tout moment en adressant un courrier électronique à notre DPO privacy@iadvize.com ;<br></br>
                ou un courrier postal à :<br/><br/>
                iAdvize (Legal)<br/>
                Euronantes Gare, Le Berlingot bât. B<br/>
                9 Rue Nina Simone<br/>
                44000 Nantes, France<br/>
                <br></br><br></br>
                Vous disposez en outre du droit d’introduire une réclamation auprès d’une autorité de contrôle.
                L’autorité de contrôle française est la Commission nationale de l’Informatique et des Libertés (CNIL), 3 Place de Fontenoy - TSA 80715 - 75334 PARIS CEDEX 07.
                
                <br/><br/>

                <strong>Politique cookies</strong><br/><br/>
                Cette rubrique est consacrée à notre politique de gestion des cookies.
                <br></br>
                Elle vous permet d’en savoir plus sur l’origine et l'usage des informations de navigation traitées à l'occasion de votre navigation sur Site et sur vos droits.
                <br></br><br></br>
                <strong>Qu'est-ce qu'un cookie ?</strong><br></br>
                La CNIL (www.cnil.fr) définit les cookies de la façon suivante :
<br></br>    
« Un cookie est une information déposée sur votre disque dur par le serveur du site que vous visitez. Le cookie a pour but de collecter des informations relatives à votre navigation et d’adresser des contenus et services adaptés à vos centres d’intérêts. Il contient plusieurs données : le nom du serveur qui l'a déposé, un identifiant sous forme de numéro unique, éventuellement une date d'expiration de cookies...  
Ces informations sont parfois stockées sur votre ordinateur dans un simple fichier texte auquel un serveur accède pour lire et enregistrer des informations. »
<br></br>
Concrètement, lors de votre visite sur le Site, des « cookies » peuvent être enregistrés sur votre terminal (votre ordinateur, votre tablette, votre smartphone, etc.).
Un cookie est un petit fichier texte stocké qui contient une liste de caractères et qui sont téléchargés sur votre terminal lors de votre visite. Les cookies sont alors renvoyés au Site à chacune de vos visites ultérieures ou bien à un autre site qui reconnaît ces cookies.<br></br><br></br>
                <strong>Les cookies présents sur le Site</strong><br></br>
                Les cookies présents sur le Site, sont utilisés dans le cadre des mesures d’audience du Site ainsi que pour répondre à des exigences techniques.<br></br><br></br>
                <strong>Les cookies de mesure d’audience</strong><br></br>
                Ces cookies sont destinés à établir la volumétrie et établir des statistiques de fréquentation du Site, des différentes rubriques consultées et des éléments utilisés.<br></br><br></br>
                <strong>La gestion de dépôt des cookies</strong><br></br>
                Les cookies sont conservés durant 12 mois maximum. Ce délai n’est pas prorogé par vos nouvelles visites.

                Le dépôt des cookies sur votre terminal suppose le recueil de votre consentement, mis à part pour les cookies “essentiels”. Ainsi, dès votre arrivée sur le Site, un bandeau d’information vous indique que nous utilisons cette technologie. Vous avez la possibilité d’accepter ou de refuser le dépôt de cookies sur votre terminal. Vous pouvez néanmoins les paramétrer à tout moment en cliquant sur le lien suivant : <a href="javascript:Didomi.preferences.show()">Paramétrer les cookies.</a><br></br><br></br>
                <strong>Le refus de cookies</strong><br></br>
                Le refus des cookies peut entrainer l’impossibilité de bénéficier de tout ou partie des fonctionnalités du Site.<br></br><br></br>
                <strong>Comment exercer vos choix selon le navigateur que vous utilisez ?</strong><br></br>
                La configuration de chaque navigateur web est différente. Elle est décrite dans le menu d'aide de votre navigateur web et vous permettra de savoir de quelle manière modifier vos souhaits en matière de cookies.
                <br></br>
                Voici les pages d’aide pour les principaux navigateurs :
                <br></br><br></br>
                Internet Explorer™ : http://windows.microsoft.com/fr-FR/windows-vista/Block-or-allow-cookies<br></br>
                Safari™ : http://docs.info.apple.com/article.html?path=Safari/3.0/fr/9277.html<br></br>
                Chrome™ : http://support.google.com/chrome/bin/answer.py?hl=fr&hlrm=en&answer=95647<br></br>
                Firefox™ : https://support.mozilla.org/fr/kb/activer-desactiver-cookies<br></br>
                Opera™ : http://help.opera.com/Windows/10.20/fr/cookies.html”<br></br><br></br>
            </div>
            <hr></hr>

            </div>
        </section>
  
}

export default CiGDPRFr



